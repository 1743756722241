<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-12 row m-0 p-0 bg-white">
          <div class="col-6 py-4 px-4 border_b_silver">
            <span class="card-label font-weight-bolder Main_Blue"
              >Advertised jobs</span
            >
          </div>
          <div class="col-6 p-0 pt-3 row m-0 text-end border_b_silver">
            <div class="col-lg-9 p-0">
              <v-text-field
                class="m-0 p-0"
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <div class="col-lg-3 p-0">
              <button
                type="button"
                class="custom-add-new-record-button btn_dash_ch mr-2"
                fab
                small
                @click="createFormEnable"
              >
                <span class="v-btn__content" style="font-size: 14px !important"
                  >Add new Job</span
                >
              </button>
            </div>
          </div>
        </div>
        <v-card class="rounded-0">
          <v-card-title>
            <!-- <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn> -->
            <v-spacer></v-spacer>
            <!-- <v-text-field
              style="width: 0%"
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            item-key="name"
            class="elevation-1 px-4"
          >
            <template v-slot:item="props">
              <tr class="border__ch">
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.job_title }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="!props.item.location_city"
                >
                  No Location
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="props.item.location_city"
                >
                  {{ props.item.location_city.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.salary_min }} - {{ props.item.salary_max }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="!props.item.job_type"
                >
                  Empty
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="props.item.job_type"
                >
                  {{ props.item.job_type.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.job_function }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="!props.item.experience"
                >
                  -
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="props.item.experience"
                >
                  {{ props.item.experience.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.education }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="!props.item.gender"
                >
                  No Preference
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                  v-if="props.item.gender"
                >
                  {{ props.item.gender.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.no_of_vacancies }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.apply_by }}
                </td>
                <!-- <td style="display: flex; flex-direction: row">
                  {{ props.item.status ? "Approved" : "Pending" }}
                  {{ props.item.status ? "Deactivated" : "Activated" }}
                  <v-btn
                    class="
                      btn btn-sm btn-success
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="editButtonClick(props.item.id)"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="
                      btn btn-sm btn-danger
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="deleteButtonClick(props.item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </td> -->
                <td class="pt-2" style="display: flex; flex-direction: row">
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    v-if="props.item.status == true"
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/Approved_icon.png"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    v-if="props.item.status == false"
                    fab
                    small
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/pending_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    v-if="props.item.status == false"
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/Verified_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    v-if="props.item.status == true"
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/unverified_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    v-if="props.item.status == true"
                  >
                    <img
                      src="\images/job_application/blocked_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="editButtonClick(props.item.id)"
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/Edit_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="deleteButtonClick(props.item)"
                  >
                    <img
                      class="dashbord"
                      src="\images/job_application/Delete.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td colspan="12" style="height: 12px"></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="createCity"
            >
              Save
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="updateCity"
            >
              Update
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      search: "",
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      city: "",
      city_id: null,
      state_id: null,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers: [
        { text: "Job Title", value: "job_title" },
        { text: "City", value: "location_city" },
        { text: "SALARY RANGE", value: "salary_range" },
        { text: "Job Type", value: "job_type" },
        { text: "Job Function", value: "job_function" },
        { text: "Experience", value: "experience" },
        { text: "Education", value: "education" },
        { text: "Gender", value: "gender" },
        { text: "No. of Vacancies", value: "no_of_vacancies" },
        { text: "Expiry date", value: "apply_by" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
    this.fetchStates();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.city = "";
      this.state_id = null;
    },
    createFormEnable() {
      this.$router.push({ name: "recruiter-job-create" });
    },
    editButtonClick(id) {
      this.data.forEach((element) => {
        if (element.id == id) {
          localStorage.setItem("job_edit_obj", JSON.stringify(element));
          this.$router.push({ name: "recruiter-job-edit" });
        }
      });
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/jobs`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchStates() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/states`)
        .then((res) => {
          res.data.forEach((element) => {
            this.statesOptions.push({
              value: element.id,
              text: element.state_name,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/cities`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.city = "";
          Swal.fire({
            title: "",
            text: "City added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/cities/${this.city_id}`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          (this.city = ""), (this.state_id = null);
          Swal.fire({
            title: "",
            text: "City updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-destroy/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Application has been deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },

    changeBlockStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-block/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Job has been updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-status/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Job has been updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>
<style>
.container {
  max-width: none !important;
}
</style>